// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
var Qs = require("qs");

@Component({})
export default class assistenzaPage extends baseUserContextPage {
	showContent: boolean = false;
	get reason(): string {
		if (this.$route.query && this.$route.query.sku != null && this.$route.query.sku != "") {
			return `Richiesta info codice prodotto: ${this.$route.query.sku}`;
		}
		return "";
	}
	created() {
		var self = this;

		setTimeout(() => {
			self.showContent = true;

		}, 1);
	}

}
